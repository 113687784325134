import vuetify from './plugins/vuetify'

import { createApp } from 'vue'
import App from './App.vue'

import { loadFonts } from './plugins/webfontloader'
import store from './store'
import router from './router'

loadFonts()

createApp(App).use(router).use(store)
  .use(vuetify)
  .mount('#app')
